$(document).foundation();

function dispWidth() {
	var dispWidth = $(window).width();
	var dispHeight = $(window).height();
	var breakpoint = Foundation.MediaQuery.current;
	$( "#dispWidth" ).text( breakpoint + ' ' + dispWidth + ' x ' + dispHeight );
}

// $('#nav-items').hide();
$('#nav-trigger').on('click','.fa-bars',function() {
	$('#nav-items').toggleClass('open');
});

// $('.gallery-wrapper .container').on('click','.item',function() {
// 	var itemNum = $(this).attr('data-item');
// 	$(this).addClass('active').siblings('.item').removeClass('active').parent('.container').removeClass('act-1 act-2 act-3').addClass('active act-' + itemNum);
// 	// console.log(itemNum + ' clicked');
// });

$('.gallery-wrapper .container').on('click','.item',function() {
	var itemIndex = $(this).attr('data-item');
	$(this).addClass('active').parent('.container').removeClass('act-1 act-2 act-3').addClass('active act-' + itemIndex);
	$(this).siblings('.item.active').removeClass('active');
	$(this).parent('.container').siblings('.container.active').removeClass('active act-1 act-2 act-3');
	console.log('clicked item-' + itemIndex);
});


// $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize){
// 	
// });
// 
// var currentBreakpoint = Foundation.MediaQuery.current;



dispWidth();
$( window ).resize(function() {
  dispWidth();
});
